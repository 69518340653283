// 所有axios的操作
import axios from 'axios'
import Vue from 'vue'
// 请求接口时加载loading，请求完后关闭loading
import { Loading } from 'element-ui'
// 导入Nprogress
import Nprogress from 'nprogress'
// import 'nprogress/nprogress.css'

// 创建一个 axios 实例
const request = axios.create({
  timeout: 1000000,
  baseURL: '/platform'
  // baseURL: 'http://127.0.0.1:5000/api',
  // withCredentials: true
})

// 创建一个 Loading 实例
let loadingInstance

function openLoading () {
  loadingInstance = Loading.service({
    lock: true,
    text: '正在加载中...',
    // 下面的spinner值可以填写自定义图标el-icon-loading
    spinner: '',
    background: 'rgba(245, 245, 245, 0.9)'
  })
}

function closeLoading () {
  loadingInstance.close()
}

Vue.prototype.$loading = loadingInstance
// import router from './router'

// axios请求前拦截处理
request.interceptors.request.use(config => {
  console.log('请求前config：' + config)

  // 显示 Loading 界面
  openLoading()

  // 在 request 拦截器中，展示进度条 Nprogress.start()
  Nprogress.start()

  // 在最后必须 return config
  return config
}, error => {
  console.log('请求参数错误')
  return Promise.reject(error)
})

// axios请求后拦截处理
request.interceptors.response.use(config => {
  console.log('请求后config：' + config)

  // 在 response 拦截器中，关闭进度条 Nprogress.done()
  Nprogress.done()
  // 隐藏 Loading 界面
  closeLoading()

  // 在最后必须 return config
  return config
}, error => {
  closeLoading()
  return Promise.reject(error)
})

export default request
